<script>
import { PolarArea, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  name: 'radar-chart-component',
  extends: PolarArea,
  mixins: [reactiveProp],
  props: ['chartData, options'],
  mounted() {
    if (this.chartData) {
      this.renderChart(this.chartData, this.options);
    }
  },
};
</script>

<style>
</style>
