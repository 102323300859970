import Vue from 'vue';
import VueRouter from 'vue-router';
import ScoreTableView from '../views/score-table.view.vue';
import MandalaView from '../views/mandala.view.vue';
import LeadCaptureView from '../views/lead-capture.view.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'score-table-view',
    component: ScoreTableView,
  },
  {
    path: '/lead-capture',
    name: 'lead-capture',
    component: LeadCaptureView,
  },
  {
    path: '/mandala',
    name: 'mandala-view',
    component: MandalaView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
