<!-- eslint-disable max-len -->
<template>
  <v-container class="">
    <v-row justify="center">
      <v-col cols="10" md="6">
        <v-row justify="center">
          <h3 class="mt-5 text-center">¡ENHORABUENA POR COMPLETAR EL MANDALA!</h3>
          <p class="mt-5 text-center">En breves minutos recibirás por correo las claves que necesitas para interpretarlo correctamente.</p>
          <p class="text-center">👉🏼 Descarga y guarda tu mandala, tenlo a mano</p>
          <p class="text-center">👉🏼 Chequea tu bandeja de entrada.</p>
        </v-row>
        <v-row v-if="dataset && dataset.length"
               justify="center"
               class="mt-10">
          <score-table-radar-chart-component :dataset="dataset" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ScoreTableRadarChartComponent from '../components/score-table-radar-chart.component.vue';

export default {
  name: 'score-table-view',
  components: {
    ScoreTableRadarChartComponent,
  },
  data: () => ({
    dataset: null,
  }),
  created() {
    const { $route } = this;
    const { params } = $route;
    const { dataset } = params;
    this.dataset = dataset;
  },
  methods: {
  },
};
</script>
