<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" md="6">
        <v-row justify="center">
          <h1 class="mt-5">MANDALA</h1>
        </v-row>
        <v-row justify="center" class="mandalabg">
          <markdown-component :source="exercise.text"/>
        </v-row>
        <v-row>
          <score-table-component :exercise="exercise"
                                 @next="next" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MarkdownComponent from '../components/markdown-component.vue';
import ScoreTableComponent from '../components/score-table.component.vue';
import ScoreTableData from '../assets/score-table.data.json';

export default {
  name: 'score-table-view',
  components: {
    ScoreTableComponent,
    MarkdownComponent,
  },
  data: () => ({
    exercise: ScoreTableData,
  }),
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let identifier = '';
    if (urlParams.has('identifier')) {
      identifier = urlParams.get('identifier');
    }
    this.$store.commit('SET_IDENTIFIER', identifier);
  },
  methods: {
    next(result) {
      this.$store.dispatch('REGISTER_MANDALA', result);
      this.$router.replace({ name: 'mandala-view', params: { dataset: result } });
    },
  },
};
</script>
