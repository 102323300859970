import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2297aa',
        secondary: '#efbd50',
        accent: '#2297aa',
        error: '#ff6400',
        info: '#2297aa',
        success: '#4CAF50',
        warning: '#efbd50',
        divider: '#707070',
        foreground: '#1F1F1F',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
