import Vue from 'vue';
import Vuex from 'vuex';
import { firestore } from 'firebase';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    identifier: undefined,
    lead: { },
    documentId: undefined,
  },
  mutations: {
    SET_IDENTIFIER: (state, identifier) => {
      state.identifier = identifier;
    },
    SET_LEAD: (state, lead) => {
      state.lead = lead;
    },
    SET_DOCUMENT_ID: (state, id) => {
      state.documentId = id;
    },
  },
  actions: {
    SET_COMPLETED_ON_BREVO_DB: async ({ state }, imageURL) => {
      const { identifier } = state;
      const url = 'https://europe-west2-pwa-charlie-production.cloudfunctions.net/app/api/v1/user/mandala/completed';
      const body = {
        email: identifier,
        imageURL,
      };
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
    },
    REGISTER_USER: async ({ commit }, lead) => {
      commit('SET_LEAD', lead);
      const Document = await firestore()
        .collection('external_mandala_leads')
        .add(lead);
      const { id } = Document;
      commit('SET_DOCUMENT_ID', id);
    },
    REGISTER_MANDALA: ({ state }, dataset) => {
      const { lead } = state;
      return firestore()
        .doc(`external_mandala_leads/${state.documentId}`)
        .update({
          ...lead,
          dataset,
        });
    },
  },
  modules: {
  },
});
