<template>
  <v-row justify="center" align="center">
    <v-col cols="10" lg="6">
      <form>
        <InputGroup title="Tu email" class="mt-5">
          <input-validator-component
                  v-model="form.email"
                  type="email"
                  rules="required|email"
                  name="email"
                  label="email"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"
                  data-cy="input-email"/>
        </InputGroup>

        <InputGroup title="¿Cómo te llamas?" class="mt-5">
          <input-validator-component
                  v-model="form.name"
                  type="name"
                  rules="required|string"
                  name="name"
                  label="nombre"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"
                  data-cy="input-name"/>
        </InputGroup>

        <InputGroup title="Tu primer apellido" class="mt-5">
          <input-validator-component
                  v-model="form.firstSurname"
                  type="name"
                  rules="required|string"
                  name="firstSurname"
                  label="primer apellido"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"
                  data-cy="input-firstSurname"/>
        </InputGroup>

        <InputGroup title="Tu segundo apellido" class="mt-5">
          <input-validator-component
                  v-model="form.secondSurname"
                  type="name"
                  rules="required|string"
                  name="secondSurname"
                  label="segundo apellido"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"
                  data-cy="input-secondSurname"/>
        </InputGroup>

        <InputGroup title="¿Qué día naciste?" class="mt-5">
          <v-row>
            <v-col cols="6" sm="3" md="3" class="mr-3">
              <input-validator-component
                      v-model="form.day"
                      placeholder="día: DD"
                      type="number"
                      rules="required|integer|between:1,31"
                      name="day"
                      label="día"
                      :min="1"
                      :max="31"
                      @status-change="onValidationStatusChange"
                      @error="onValidationError"
                      data-cy="input-day"/>
            </v-col>
            <v-col cols="6" sm="3" md="3" class="mr-3">
              <input-validator-component
                      v-model="form.month"
                      placeholder="mes: MM"
                      type="number"
                      rules="required|integer|between:01,12"
                      name="month"
                      label="mes"
                      :min="1"
                      :max="12"
                      @status-change="onValidationStatusChange"
                      @error="onValidationError"
                      data-cy="input-month"/>
            </v-col>

            <v-col cols="6" sm="3" md="3" class="mr-3">
              <input-validator-component
                      v-model="form.year"
                      type="number"
                      placeholder="Año: YYYY"
                      rules="required|integer"
                      name="year"
                      label="año"
                      :min="1970"
                      :max="new Date().getFullYear()"
                      @status-change="onValidationStatusChange"
                      @error="onValidationError"
                      data-cy="input-year"/>
            </v-col>
          </v-row>
        </InputGroup>

        <v-row class="ml-2" align="center">
          <v-checkbox class="mb-3"
                      v-model="form.law.privacyPolicies"
                      data-cy="checkbox-privacyPolicies"></v-checkbox>
          <v-col cols="10">
            <p>
              Aceptas las
              <a :href="privacyPoliciesURL"
                 target="_blank">Políticas de privacidad y protección de datos</a>.
            </p>
          </v-col>
        </v-row>
        <v-row class="ml-2" align="center">
          <v-checkbox class="mb-3"
                      v-model="form.law.legalWarning"
                      data-cy="checkbox-legalWarning"></v-checkbox>
          <p>Aceptas el <a :href="legalWarningURL" target="_blank">Aviso legal</a> Charlie.</p>
        </v-row>
      </form>

      <v-row justify="center">
        <v-btn  color="primary"
                @click.prevent="register"
                :disabled="!isFormValid || isDisabledSubmitButton"
                data-cy="btn-begin">Empezar</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import InputGroup from '../components/InputGroup.vue';
import InputValidatorComponent from '../components/InputValidatorComponent.vue';
import validationsMixin from '../mixins/validations';

export default {
  name: 'lead-capture-view',
  components: { InputValidatorComponent, InputGroup },
  mixins: [validationsMixin],
  data: () => ({
    form: {
      email: '',
      name: '',
      firstSurname: '',
      secondSurname: '',
      day: '',
      month: '',
      year: '',
      law: {
        legalWarning: false,
        privacyPolicies: false,
      },
    },
    validation: {
      success: {
        email: false,
        name: false,
        firstSurname: false,
        secondSurname: false,
        day: false,
        month: false,
        year: false,
      },
      errors: {},
    },
    isDisabledSubmitButton: false,
    legalWarningURL: `${process.env.VUE_APP_WEB_SITE}aviso-legal/`,
    privacyPoliciesURL: `${process.env.VUE_APP_WEB_SITE}politica-de-privacidad/`,
  }),
  methods: {
    register() {
      debugger;
      if (this.lawRequirementsMarked()) {
        this.enableSubmitButton();
      } else {
        this.form.birthdate = `${this.form.year}-${this.form.month}-${this.form.day}`;

        this.$store
          .dispatch('REGISTER_USER', this.form)
          .then(this.successHandler)
          .catch(this.errorHandler);
      }
    },
    successHandler() {
      this.$router.replace('/score-table');
    },
    errorHandler(error) {
      console.log(error);
    },
    lawRequirementsMarked() {
      return (
        this.form.law.legalWarning === false
        || this.form.law.privacyPolicies === false
      );
    },
    enableSubmitButton() {
      this.isDisabledSubmitButton = false;
    },
    disableSubmitButton() {
      this.isDisabledSubmitButton = true;
    },
  },
};
</script>
