import './assets/main.scss';
import Vue from 'vue';
import VueMarkdown from 'vue-markdown';
import firebase from 'firebase';
import FirebaseConfig from './plugins/firebase/conf';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueMarkdown);

firebase.initializeApp(FirebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
