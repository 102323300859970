<template>
  <div>
    <v-stepper v-model="currentStep"
              class = "elevation-0"
              vertical>
      <div v-for="(questionBlock, index) in exercise.dataset" :key="index">
        <v-stepper-step :complete="whenStepIsComplete(index)"
                        :step="buildStepIdentifier(index)">
          {{questionBlock.title}}
        </v-stepper-step>

        <v-stepper-content :step="buildStepIdentifier(index)">
          <v-row v-for="(question, idx) in questionBlock.questions"
                :key="idx"
                :class="{
                  'mt-8': idx > 0,
                  'ml-0': true,
                  'question-row': true
                }">
            <question-row-component :question="question" />
          </v-row>
          <v-row justify="space-around" class="mt-10 mb-5">
            <v-btn v-if="!isFirstStep"
                  color="primary"
                  outlined
                  x-large
                  @click="backStep()">Anterior</v-btn>
            <v-btn v-if="!isLastStep"
                  @click="nextStep()"
                  color="primary"
                  x-large
                  :data-cy="`btn-next-step`">Siguiente</v-btn>
            <v-btn v-if="isLastStep"
                  @click="next()"
                  color="primary"
                  x-large
                  :data-cy="`btn-next-exercise`">Continuar</v-btn>
          </v-row>
        </v-stepper-content>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import QuestionRowComponent from './question-row-component.vue';

const INITIAL_STEP = 1;

export default {
  name: 'score-table-component',
  components: { QuestionRowComponent },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentStep: INITIAL_STEP,
  }),
  methods: {
    next() {
      this.$emit('next', this.exercise.dataset);
    },
    buildStepIdentifier(index) {
      return index + 1;
    },
    whenStepIsComplete(index) {
      const STEP_IDENTIFIER = this.buildStepIdentifier(index);
      return this.currentStep > STEP_IDENTIFIER;
    },
    nextStep() {
      if (!this.isValidForm()) return;
      const STESP_TO_GO_FORWARD = 1;
      this.currentStep += STESP_TO_GO_FORWARD;
    },
    backStep() {
      if (!this.isValidForm()) return;
      const STEPS_TO_BACK = 1;
      this.currentStep -= STEPS_TO_BACK;
    },
    isValidForm() {
      for (let index = 0; index < this.exercise.dataset.length; index += 1) {
        const item = this.exercise.dataset[index];
        const isInvalid = item.questions.find((question) => (
          question.howIFeel <= -1
          || question.howIFeel >= 11
          || question.howDoIWantToSeeMe <= -1
          || question.howDoIWantToSeeMe >= 11
        ));
        if (isInvalid) {
          this.$swal({
            type: 'info',
            title: 'Oops!',
            text: 'Ningun campo puede ser menor que 0 o mayor que 10.',
            confirmButtonColor: '#2297aa',
          });
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    isFirstStep() {
      return this.currentStep === INITIAL_STEP;
    },
    isLastStep() {
      const LAST_STEP = this.exercise.dataset.length;
      return this.currentStep === LAST_STEP;
    },
  },
};
</script>
