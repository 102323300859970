<template>
  <div>
    <radar-chart-component :chart-data="chartData"
                           :options="options" />
    <v-row justify="center"
           class="mt-10">
      <v-btn @click="download"
             color="primary">Descargar</v-btn>
    </v-row>
  </div>
</template>

<script>
import QuickChart from 'quickchart-js';
import RadarChartComponent from './radar-chart.component.vue';

export default {
  name: 'score-table-radar-chart-component',
  components: { RadarChartComponent },
  props: {
    dataset: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    quickChart: null,
    chartData: null,
    options: {
      responsive: true,
    },
  }),
  async created() {
    this.chartData = {
      labels: this.dataset.map((item) => item.title),
      datasets: this.getChartData(),
    };
    this.chartData = {
      labels: this.dataset.map((item) => item.title),
      datasets: this.getChartData(),
    };
    this.quickChart = new QuickChart();
    this.quickChart.setWidth(500);
    this.quickChart.setHeight(500);
    this.quickChart.setConfig({
      type: 'polarArea',
      format: 'png',
      data: this.chartData,
    });
    const imgURL = await this.quickChart.getShortUrl();
    this.$store.dispatch('SET_COMPLETED_ON_BREVO_DB', imgURL);
  },
  methods: {
    getChartData() {
      const lastQuestions = this.dataset.map((item) => {
        const lastQuestion = item.questions[item.questions.length - 1];
        return {
          howIFeel: lastQuestion.howIFeel,
          howDoIWantToSeeMe: lastQuestion.howDoIWantToSeeMe,
        };
      });
      return [
        {
          label: 'COMO ME SIENTO',
          borderColor: '#efbd50',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: lastQuestions.map((question) => question.howIFeel),
          backgroundColor: [
            'rgba(210, 224, 237, 1)', // #d2e0ed
            'rgba(239, 189, 80, 1)', // #efbd50
            'rgba(248, 244, 231, 1)', // #f8f4e7
            'rgba(255, 100, 0, 1)', // #ff6400
            'rgba(242, 200, 170, 1)', // #f2c8aa
            'rgba(34, 151, 170, 1)', // #2297aa
          ],
        },
        {
          label: 'COMO QUIERO VERME',
          backgroundColor: [],
          borderColor: '#2297aa',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: lastQuestions.map((question) => question.howDoIWantToSeeMe),
        },
      ];
    },
    async download() {
      if (!this.quickChart) {
        this.chartData = {
          labels: this.dataset.map((item) => item.title),
          datasets: this.getChartData(),
        };
        this.quickChart = new QuickChart();
        this.quickChart.setWidth(500);
        this.quickChart.setHeight(500);
        this.quickChart.setConfig({
          type: 'polarArea',
          format: 'png',
          data: this.chartData,
        });
      }
      window.open(await this.quickChart.getShortUrl());
    },
  },
};
</script>
